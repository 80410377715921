@import "src/themes/daikiri/styles/index.scss";
.Checkbox {
  display: block;
  position: relative;

  & label {
    position: relative;
    padding-left: space(1.75);
    cursor: pointer;
    display: block;
    text-align: left;
    font-size: 0.9rem;
    top: -2px;
    @include md {
      font-size: 1rem;
    }
  }

  &__item {
    display: inline-block;
    position: absolute;
    cursor: pointer;
    top: 2px;
    left: 0;
    height: space(1.15);
    width: space(1.15);
    cursor: pointer;
  }

  &__error-message {
    display: flex;
    margin-top: space(0.25);
    margin-bottom: space(0.5);
    align-items: center;
    color: getvar($colors, "error", "600");

    svg {
      color: getvar($colors, "error", "600");
      margin-right: space(0.25);
      min-height: 14px;
      min-width: 14px;
    }
  }

  &--disabled {
    pointer-events: none;
    cursor: not-allowed;

    & label {
      color: getvar($colors, "neutral", "300");
    }

    & label:before {
      border: 2px solid getvar($colors, "neutral", "200");
    }
  }
}

/* customs styles for checkbox */
.Checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.Checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.15rem;
  width: 1.15rem;
  background-color: transparent;
  border-radius: 3px;
  border: 1px solid rgb(191, 191, 191);
}

/* On mouse-over, add a grey background color */
.Checkbox-container:hover input ~ .checkmark {
  background-color: transparent;
}

.Checkbox-container input:checked ~ .checkmark {
  background-color: getvar($colors, "primary", "500");
  border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.Checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.Checkbox-container .checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
