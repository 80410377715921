@import "src/themes/daikiri/styles/index.scss";
.imageUpload {
  margin-bottom: space(1.5);

  &__wrapper {
    display: flex;
    align-items: center;

    & button {
      margin: 0 !important;
      margin-left: space(2) !important;
    }
  }

  &__item {
    border-radius: space(0.5);
    border: 1px solid getvar($colors, "neutral", "300");
    display: inline-block;
    padding: space(0.5) space(0.4);
    position: relative;
    width: space(4);
    height: space(4);
  }

  &__add {
    position: absolute;
    top: -10px;
    right: -10px;
    color: getvar($colors, "neutral", "50");
    background: getvar($colors, "neutral", "900");
    display: inline-block;
    line-height: 1;
    width: space(1.8);
    height: space(1.8);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    cursor: pointer;

    & svg {
      max-height: 0.9rem;
    }

    &:hover {
      opacity: 0.8;
    }
    & span {
      padding-bottom: 1.5px;
    }
  }

  &__svg {
    background-color: getvar($colors, "neutral", "100");
    border-radius: space(0.5);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    div {
      display: flex;
    }
  }
}

.image-cropper_modal_overlay {
  z-index: 4 !important;
}
